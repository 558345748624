import * as React from 'react';
import {ImageBaseUrl} from '../Constants';
import {HashLink as Link} from 'react-router-hash-link';

export const Home: React.FC = () => (
    <div id='homepage'>
        <div className='topic'>
            <img className='topic-img'
                 src={ImageBaseUrl + 'Bild_HP_Kurse_und_Babymassagekurs_und_Babymassage_Workshop.jpg'}
                 alt='Kurse und Babymassage'/>

            <Link to='/kurse'>
                <h2>Kurse</h2>
            </Link>

            <Link to='/kurse#babymassage'>Babymassage</Link>
            <Link to="/kurse#babysteps">BabySteps&nbsp;&reg;</Link>
            <Link to="/kurse#bellybasics">BellyBasics&nbsp;&reg;</Link>
            <Link to="/kurse#hulates">Hulates</Link>
            <Link to="/kurse#maxisteps">MaxiSteps&nbsp;&reg;</Link>
            <Link to="/kurse#minisigns">MiniSigns&nbsp;&reg;</Link>
            <Link to="/kurse#mommysteps">MommySteps&nbsp;&reg;</Link>
            <Link to="/kurse#wackelwippen">WACKELWIPPEN&nbsp;&reg;</Link>
            <Link to='/termine_anmeldung'>Termine/Anmeldung</Link>
        </div>

        <div className='topic'>
            <img className='topic-img'
                 src={ImageBaseUrl + 'Bild_HP_Workshops.jpg'}
                 alt='Kurse und Babymassage'/>

            <Link to='/workshops'>
                <h2>Workshops</h2>
            </Link>

            <Link to='/workshops#babyabc'>BabyABC</Link>
            <Link to='/workshops#beikost'>Beikost</Link>
            <Link to='/workshops#babyschlaf'>Babyschlaf</Link>
            <Link to='/workshops#babytragen'>Babytragen</Link>
            <Link to='/workshops#einfach_eltern'>Einfach Eltern&nbsp;&reg;</Link>
            <Link to='/workshops#geschwisterkurs'>Geschwisterkurs</Link>
            <Link to='/workshops#kinderkrankheiten'>Kinderkrankheiten nat&uuml;rlich&nbsp;lindern</Link>
            <Link to='/termine_anmeldung'>Termine/Anmeldung</Link>
        </div>

        <div className='topic'>
            <img className='topic-img'
                 src={ImageBaseUrl + 'Bild_HP_Beratung.jpg'}
                 alt='Kurse und Babymassage'/>

            <Link to='/beratungen'>
                <h2>Beratungen</h2>
            </Link>

            <Link to='/beratungen#trageberatung'>Trageberatung</Link>
            <Link to='/beratungen#beratungsequipment'>Beratungsequipment</Link>
            <Link to='/beratungen#babyschlafberatung'>Babyschlafberatung</Link>
        </div>

        <div className='topic'>
            <img className='topic-img'
                 src={ImageBaseUrl + 'BellyParty_Kategorie.jpg'}
                 alt='Kurse und Babymassage'/>

            <Link to='/bellyparty'>
                <h2>BellyParty</h2>
            </Link>

            <Link to='/bellyparty'>BellyParty</Link>
        </div>

        <div className='topic'>
            <img className="topic-img"
                 src={ImageBaseUrl + 'Bild_HP_Specials.jpg'}
                 alt="Angebote im Babys im Glück"/>

            <Link to="/angebote">
                <h2>Angebote mit …</h2>
            </Link>

            <Link to="/angebote#angebote_susanne">Angebote mit Susanne</Link>
            <Link to="/angebote#angebote_jenny">Angebote mit Jenny</Link>
            <Link to="/angebote#angebote_karen">Angebote mit Karen</Link>
            <Link to="/angebote#angebote_laura">Angebote mit Laura</Link>
            <Link to="/angebote#angebote_antje">Angebote mit Antje</Link>
            <Link to="/angebote#kooperationspartner">Kooperationspartner</Link>
        </div>

        <div className='topic'>
            <img className="topic-img"
                 src={ImageBaseUrl + 'Startseite_Reiterbild_BabysimGlück.jpg'}
                 alt="Kurse und Babymassage"/>

            <Link to='/babys_im_glueck'>
                <h2>Babys im Glück</h2>
            </Link>

            <Link to='/babys_im_glueck#ueber_mich'>&Uuml;ber mich</Link>
            <Link to='/babys_im_glueck#anfahrt'>Anfahrt</Link>
            <Link to='/babys_im_glueck#gutscheine'>Gutscheine</Link>
            <Link to='/babys_im_glueck#raummiete'>Raummiete</Link>
            <Link to='/babys_im_glueck#kontakt'>Kontakt</Link>
        </div>
    </div>
);
