import * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Header} from './Header';
import {MarkdownContent} from './MarkdownContent';
import {Home} from './pages/Home';
import {Kurse} from './pages/Kurse';
import {Workshops} from './pages/Workshops';
import {Beratungen} from './pages/Beratungen';
import {Angebote} from './pages/Angebote';
import {BabysImGlueck} from './pages/BabysImGlueck';
import {TermineAnmeldung} from './pages/TermineAnmeldung';
import {Bellyparty} from './pages/Bellyparty';
import MenuIcon from '@mui/icons-material/Menu';
import {Footer} from './Footer';

type MainProps = {
    handleToggleSidebar: (value: boolean) => void
}

export const Main: React.FC<MainProps> = ({handleToggleSidebar}) => (
    <div className={'main-content-container'}>
        <main>
            <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
                <MenuIcon/>
            </div>

            <Header/>

            <Routes>
                <Route path={'/*'} element={<Home/>}/>

                <Route path={'/kurse'} element={<Kurse/>}/>
                <Route path={'/workshops'} element={<Workshops/>}/>
                <Route path={'/beratungen'} element={<Beratungen/>}/>
                <Route path={'/bellyparty'} element={<Bellyparty/>}/>
                <Route path={'/angebote'} element={<Angebote/>}/>
                <Route path={'/babys_im_glueck'} element={<BabysImGlueck/>}/>
                <Route path={'/termine_anmeldung'} element={<TermineAnmeldung/>}/>

                <Route path={'/impressum'} element={
                    <div className={'page'}>
                        <MarkdownContent file="impressum.md"/>
                    </div>}/>
                <Route path={'/datenschutz'} element={
                    <div className={'page'}>
                        <MarkdownContent file="datenschutz.md"/>
                    </div>}/>
                <Route path={'/agb'} element={
                    <div className={'page'}>
                        <MarkdownContent file="agb.md"/>
                    </div>}/>
            </Routes>

            <Footer/>
        </main>
    </div>
);
