import * as React from 'react';
import {EdooBox} from '../EdooBox';
import {MarkdownContent} from '../MarkdownContent';

export const TermineAnmeldung: React.FC = () => (
    <div className={'page'}>
        <MarkdownContent file='kursorte_termine.md'/>
        <EdooBox/>
    </div>
);
