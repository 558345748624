import { baseApi as api } from "./api.base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    courseRoomResourceGetAll: build.query<
      CourseRoomResourceGetAllApiResponse,
      CourseRoomResourceGetAllApiArg
    >({
      query: () => ({ url: `/course_room` }),
    }),
    courseRoomResourceCreate: build.mutation<
      CourseRoomResourceCreateApiResponse,
      CourseRoomResourceCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/course_room`,
        method: "POST",
        body: queryArg.courseRoomParameter,
      }),
    }),
    courseRoomResourceGet: build.query<
      CourseRoomResourceGetApiResponse,
      CourseRoomResourceGetApiArg
    >({
      query: (queryArg) => ({ url: `/course_room/${queryArg.id}` }),
    }),
    courseRoomResourceUpdate: build.mutation<
      CourseRoomResourceUpdateApiResponse,
      CourseRoomResourceUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/course_room/${queryArg.id}`,
        method: "PUT",
        body: queryArg.courseRoomParameter,
      }),
    }),
    courseRoomResourceDelete: build.mutation<
      CourseRoomResourceDeleteApiResponse,
      CourseRoomResourceDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/course_room/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type CourseRoomResourceGetAllApiResponse =
  /** status 200 OK */ CourseRoom[];
export type CourseRoomResourceGetAllApiArg = void;
export type CourseRoomResourceCreateApiResponse = unknown;
export type CourseRoomResourceCreateApiArg = {
  courseRoomParameter: CourseRoomParameter;
};
export type CourseRoomResourceGetApiResponse = /** status 200 OK */ CourseRoom;
export type CourseRoomResourceGetApiArg = {
  id: number;
};
export type CourseRoomResourceUpdateApiResponse = unknown;
export type CourseRoomResourceUpdateApiArg = {
  id: number;
  courseRoomParameter: CourseRoomParameter;
};
export type CourseRoomResourceDeleteApiResponse = unknown;
export type CourseRoomResourceDeleteApiArg = {
  id: number;
};
export type CourseRoom = {
  id?: number;
  name?: string;
  description?: string;
  address?: string;
};
export type CourseRoomParameter = {
  name?: string;
  description?: string;
  address?: string;
};
export const {
  useCourseRoomResourceGetAllQuery,
  useCourseRoomResourceCreateMutation,
  useCourseRoomResourceGetQuery,
  useCourseRoomResourceUpdateMutation,
  useCourseRoomResourceDeleteMutation,
} = injectedRtkApi;
