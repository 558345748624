import React from "react";
import {Link} from "react-router-dom";

export const Footer: React.FC = () => (
    <footer>
        <small>
            <Link to={'/impressum'}>Impressum</Link>
            <Link to={'/agb'}>AGB</Link>
            <Link to={'/datenschutz'}>Datenschutz</Link>
        </small>
    </footer>
);
