import * as React from 'react';
import {MarkdownContent} from '../MarkdownContent';
import {ScrollUtil} from "../util/ScrollUtil";
import {HashLink as Link} from "react-router-hash-link";

export const Workshops: React.FC = () => {
    return (
        <div id='workshops' className='page'>
            <h2 id='babyabc'>BabyABC</h2>
            <MarkdownContent file='babyabc.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id='beikost'>Beikost</h2>
            <MarkdownContent file='beikost.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to='/termine_anmeldung'>Termine/Anmeldung</Link>

            <h2 id='babyschlaf'>Babyschlaf</h2>
            <MarkdownContent file='babyschlaf.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to='/termine_anmeldung'>Termine/Anmeldung</Link>

            <h2 id='babytragen'>Babytragen</h2>
            <MarkdownContent file='babytragen.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to='/termine_anmeldung'>Termine/Anmeldung</Link>

            <h2 id='einfach_eltern'>Einfach Eltern&reg;</h2>
            <MarkdownContent file='einfach_eltern.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to='/termine_anmeldung'>Termine/Anmeldung</Link>

            <h2 id='geschwisterkurs'>Geschwisterkurs</h2>
            <MarkdownContent file='geschwisterkurs.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to='/termine_anmeldung'>Termine/Anmeldung</Link>

            <h2 id='kinderkrankheiten'>Kinderkrankheiten nat&uuml;rlich&nbsp;lindern</h2>
            <MarkdownContent file='kinderkrankheiten_natuerlich_lindern.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to='/termine_anmeldung'>Termine/Anmeldung</Link>
        </div>
    );
}
