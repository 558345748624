import * as React from 'react';

export const EdooBox: React.FC = () => {
    return (
        <>
            <iframe src="https://app1.edoobox.com/SaOtt/?edref=saott"
                    name="edooboxFrame_saott"
                    id="edoobox_saott"
                    frameBorder="0"
                    seamless
                    data-scrolltop>
                <a href="https://app1.edoobox.com/SaOtt/?edref=saott">Übersicht anzeigen</a>
            </iframe>
            <script type="text/javascript" src={"https://cdn1.edoobox.com/edoobox.iframe.embedded.v2.2.js"}/>
        </>
    );
}
