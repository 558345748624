import React from 'react';
import {Link} from 'react-router-dom';
import {InfoBox} from "./InfoBox";
import {ImageBaseUrl} from "./Constants";

export const Header: React.FC = () => (
    <header className='header'>
        <Link to={'/'}>
            <img alt={'Babys im Gl&uuml;ck Logo'} src={ImageBaseUrl + 'babys-im-glueck-logo-schriftzug-neu.svg'}/>
        </Link>
        <InfoBox/>
    </header>
);
