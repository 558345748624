import * as React from 'react';
import {useEffect, useState} from 'react';
import {ContentBaseUrl} from './Constants';
import ReactMarkdown from 'react-markdown';

type MarkdownContentProps = {
    file: string;
    onContentLoaded?: () => void
}

export const MarkdownContent: React.FC<MarkdownContentProps> = ({file, onContentLoaded}) => {

    const [innerHtml, setInnerHtml] = useState<string>('');

    useEffect(() => {
        fetch(ContentBaseUrl + file)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error('Fetching resource failed with ' + response.status);
                }
                return response.text();
            })
            .then(text => {
                setInnerHtml(text);
            })
            .catch(() => {
                setInnerHtml('');
            });
    }, [file]);

    useEffect(() => {
        if (onContentLoaded && innerHtml !== '') {
            onContentLoaded();
        }
    }, [onContentLoaded, innerHtml]);

    return (
        <ReactMarkdown className={'content-container'}>
            {innerHtml}
        </ReactMarkdown>
    );
};
