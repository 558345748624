import * as React from 'react';
import {MarkdownContent} from '../MarkdownContent';
import {ScrollUtil} from "../util/ScrollUtil";

export const BabysImGlueck: React.FC = () => {
    return (
        <div id='babysImGlueck' className='page'>
            <h2 id='ueber_mich'>&Uuml;ber mich</h2>
            <MarkdownContent file='ueber_mich.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id='anfahrt'>Anfahrt</h2>
            <MarkdownContent file='anfahrt.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id='gutscheine'>Gutscheine</h2>
            <MarkdownContent file='gutscheine.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id='raummiete'>Raummiete</h2>
            <MarkdownContent file='raummiete.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id='kontakt'>Kontakt</h2>
            <MarkdownContent file='kontakt.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>
        </div>
    );
}
