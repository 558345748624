import * as React from 'react';
import {MarkdownContent} from '../MarkdownContent';
import {ScrollUtil} from '../util/ScrollUtil';

export const Angebote: React.FC = () => {
    return (
        <div id="team" className="page">
            <h2 id="angebote_susanne">Angebote mit Susanne</h2>
            <MarkdownContent file="angebote_susanne.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id="angebote_jenny">Angebote mit Jenny</h2>
            <MarkdownContent file="angebote_jenny.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id="angebote_karen">Angebote mit Karen</h2>
            <MarkdownContent file="angebote_karen.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id="angebote_laura">Angebote mit Laura</h2>
            <MarkdownContent file="angebote_laura.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id="angebote_antje">Angebote mit Antje</h2>
            <MarkdownContent file="angebote_antje.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id="kooperationspartner">Kooperationspartner</h2>
            <MarkdownContent file="kooperationspartner.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>
        </div>
    );
}
