import '../css/App.scss';
import * as React from 'react';
import {useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Main} from './Main';
import smoothscroll from 'smoothscroll-polyfill';
import {Sidebar} from "./Sidebar";

const App: React.FC = () => {
    smoothscroll.polyfill();

    const [toggled, setToggled] = useState(false);

    const handleToggleSidebar = (value: boolean) => {
        setToggled(value);
    };

    return (
        <Router>
            <div className={'app'}>
                <Sidebar toggled={toggled} handleToggleSidebar={handleToggleSidebar}/>
                <Main handleToggleSidebar={handleToggleSidebar}/>
            </div>
        </Router>
    );
}

export default App;
