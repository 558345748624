import React from 'react';
import {Link} from 'react-router-dom';
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader} from 'react-pro-sidebar';
import FacebookIcon from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import {ImageBaseUrl} from './Constants';

type SidebarProps = {
    toggled: boolean,
    handleToggleSidebar: (value: boolean) => void
}

export const Sidebar: React.FC<SidebarProps> = ({toggled, handleToggleSidebar}) => (
    <div className={'sidebar-container'}>
        <ProSidebar breakPoint={'md'} toggled={toggled} onToggle={handleToggleSidebar}>
            <SidebarHeader>
                <Link to="/">
                    <img src={ImageBaseUrl + 'Startseite_Sitebar_v2.jpg'} alt={'Symbolbild von Babys im Glück'}/>
                </Link>
            </SidebarHeader>
            <SidebarContent>
                <Menu iconShape={'round'}>
                    <MenuItem>
                        Kurse
                        <Link to={'/kurse'}/>
                    </MenuItem>
                    <MenuItem>
                        Workshops
                        <Link to={'/workshops'}/>
                    </MenuItem>
                    <MenuItem>
                        Beratungen
                        <Link to={'/beratungen'}/>
                    </MenuItem>
                    <MenuItem>
                        BellyParty
                        <Link to={'/bellyparty'}/>
                    </MenuItem>
                    <MenuItem>
                        Angebote mit …
                        <Link to={'/angebote'}/>
                    </MenuItem>
                    <MenuItem>
                        Babys im Gl&uuml;ck
                        <Link to={'/babys_im_glueck'}/>
                    </MenuItem>
                    <MenuItem>
                        Termine/Anmeldung
                        <Link to={'/termine_anmeldung'}/>
                    </MenuItem>
                    <MenuItem>
                        Kontakt
                        <Link to={'/babys_im_glueck#kontakt'}/>
                    </MenuItem>
                </Menu>
            </SidebarContent>
            <SidebarFooter>
                <div className={'social-media-icons'}>
                    <a href={'https://www.instagram.com/sandra_babysimglueck/?hl=de'}>
                        <Instagram sx={{fontSize: '2em'}}/>
                    </a>
                    <a href={'https://www.facebook.com/BabysimGlueck'}>
                        <FacebookIcon sx={{fontSize: '2em'}}/>
                    </a>
                </div>
                <div>
                    <Link to={'/impressum'}>Impressum</Link>
                </div>
                <div>
                    <Link to={'/agb'}>AGB</Link>
                    <Link to={'/datenschutz'}>Datenschutz</Link>
                </div>
            </SidebarFooter>
        </ProSidebar>
    </div>
);
