import * as React from 'react';
import {MarkdownContent} from '../MarkdownContent';
import {ScrollUtil} from "../util/ScrollUtil";

export const Beratungen: React.FC = () => {
    return (
        <div id='beratungen' className='page'>
            <h2 id='trageberatung'>Trageberatung</h2>
            <MarkdownContent file='trageberatung.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id='beratungsequipment'>Beratungequipment</h2>
            <MarkdownContent file='beratungsequipment.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>

            <h2 id='babyschlafberatung'>Babyschlafberatung</h2>
            <MarkdownContent file='babyschlafberatung.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>
        </div>
    );
}
