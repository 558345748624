import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

enum ApiEndpoint {
    PRODUCTION = 'https://api.taujhe.de/',
    TEST = 'https://api.test.taujhe.de/',
    LOCAL = 'http://localhost:8080/'
}

const TestUrlPattern = /^localhost(:[0-9]+)?$|^([a-zA-z0-9]+\.)?test\.taujhe\.de$/;

const baseUrl = window.location.host.match(TestUrlPattern) ? ApiEndpoint.TEST : ApiEndpoint.PRODUCTION;

const baseQuery = fetchBaseQuery({
    baseUrl
});

export const baseApi = createApi({
    baseQuery: baseQuery,
    endpoints: () => ({})
});
