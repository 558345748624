import * as React from 'react';
import {MarkdownContent} from '../MarkdownContent';
import {ScrollUtil} from '../util/ScrollUtil';
import {HashLink as Link} from 'react-router-hash-link';

export const Kurse: React.FC = () => {
    return (
        <div id='kurse' className='page'>
            <h2 id='babymassage'>Babymassage</h2>
            <MarkdownContent file='babymassage_kurs.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to='/termine_anmeldung'>Termine/Anmeldung</Link>

            <h2 id="babysteps">BabySteps&nbsp;&reg;</h2>
            <MarkdownContent file="babysteps.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to="/termine_anmeldung">Termine/Anmeldung</Link>

            <h2 id="bellybasics">BellyBasics&nbsp;&reg;</h2>
            <MarkdownContent file="bellybasics.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to="/termine_anmeldung">Termine/Anmeldung</Link>

            <h2 id="hulates">Hulates</h2>
            <MarkdownContent file="hulates.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to="/termine_anmeldung">Termine/Anmeldung</Link>

            <h2 id="maxisteps">MaxiSteps&nbsp;&reg;</h2>
            <MarkdownContent file="maxisteps.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to="/termine_anmeldung">Termine/Anmeldung</Link>

            <h2 id="minisigns">MiniSigns&nbsp;&reg;</h2>
            <MarkdownContent file="minisigns.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to="/termine_anmeldung">Termine/Anmeldung</Link>

            <h2 id="minisigns">MommySteps&nbsp;&reg;</h2>
            <MarkdownContent file="mommysteps.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to="/termine_anmeldung">Termine/Anmeldung</Link>

            <h2 id="wackelwippen">WACKELWIPPEN&nbsp;&reg;</h2>
            <MarkdownContent file="wackelwippen.md" onContentLoaded={ScrollUtil.scrollToHashElement}/>
            <Link to="/termine_anmeldung">Termine/Anmeldung</Link>
        </div>
    );
};
