import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {api} from '../../client/api.generated';

const store = configureStore({
    reducer: {[api.reducerPath]: api.reducer},
    devTools: true,
    middleware: (getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware))
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
