import * as React from "react";
import {ScrollUtil} from "../util/ScrollUtil";
import {MarkdownContent} from "../MarkdownContent";

export const Bellyparty: React.FC = () => {
    return (
        <div id='bellyparty' className='page'>
            <h2 id='bellyparty'>BellyParty</h2>
            <MarkdownContent file='bellyparty.md' onContentLoaded={ScrollUtil.scrollToHashElement}/>
        </div>
    );
}
