import * as React from 'react';
import {useEffect, useState} from 'react';

export const InfoBox: React.FC = () => {

    const [infoText, setInfoText] = useState('');

    useEffect(() => {
        fetch('https://static.babys-im-glueck.de/content/infotext')
            .then(result => result.text())
            .then(text => setInfoText(text));
    });

    const renderInfoText = infoText.length > 0;
    return (
        <>
            {renderInfoText && <div id='infobox'>{infoText}</div>}
        </>
    );
}
